<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="OKRData === undefined">
      <h4 class="alert-heading">Error fetching OKR data</h4>
      <div class="alert-body">
        No OKR found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'okrs' }">
          referral List
        </b-link>
      </div>
    </b-alert>
    <template  v-if="OKRData">
      <b-row>
        <b-col md="12">
          <b-card title="Recent OKRs">
            <b-row>
            <div class="scroll">
            <b-button v-for="(list_okr) in OKRData.user_okrs"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="(list_okr.hashid == OKRData.hashid )? 'primary': 'outline-primary'"
              class="ml-1"
              @click="viewRecord(list_okr)"
              v-if="$can('okr_show')"
            >
              {{list_okr.period_range}}
            </b-button>
              <!-- <b-row>
                <b-col
                  md="2"
                  class="pt-2"
                >
                  OKR Progress : 
                </b-col>
                <b-col
                  md="10"
                  class="pt-md-2"
                >
                  <b-progress
                    key="progress"
                    animated
                    variant="primary"
                    class="'progress-bar-primary"
                  >
                  <b-progress-bar v-for="(progress,key) in getProgress" v-if="key == 'Completed'"
                    :value="progress.value"
                    :variant="progress.class"
                    :title="key"
                  >
                  <strong>{{progress.value}}%</strong>
                  </b-progress-bar>
                  </b-progress>
                </b-col>
              </b-row> -->
            </div>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-if="OKRData">
      <!-- First Row -->
      <b-row>
        <b-col cols="9" xl="9" lg="9" md="9">
          <okr-view-info
            :data="OKRData"
            :custom-fields="customFields"
          />
        </b-col>
        <b-col cols="3" xl="3" lg="3" md="3">
          <b-card style="width:100%; height:100%;">
            <img :src="OKRData.user.avatar" style="width:100%; height:100%;border-radius: 25%;"/>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template  v-if="OKRData">
      <b-row>
        <b-col md="12">
          <b-card class="mt-2">
            <b-row>
              <b-col
                md="2"
                class="pt-2"
              >
                OKR Progress : 
              </b-col>
              <b-col
                md="10"
                class="pt-md-2"
              >
                <b-progress
                  key="progress"
                  animated
                  variant="primary"
                  class="'progress-bar-primary"
                >
                <b-progress-bar v-for="(progress,key) in getProgress" v-if="key == 'Completed'"
                  :value="progress.value"
                  :variant="progress.class"
                  :title="key"
                >
                <strong>{{progress.value}}%</strong>
                </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template>
      <b-row>
        <b-col md="12">
          <b-card>
            <b-tabs>
              <!-- top position -->
              <b-tab :title='"Objectives("+getObjectives.length+")"' active>
                <b-tabs v-for="(objective,k) in getObjectives" :key="k"
                  vertical
                  nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
                  <b-tab
                    active
                    :title='"Objective "+(k+1)'
                  >
                    <b-card-text >
                      <b-row>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="2" >
                            <b-badge
                              :variant="`${getStatusClass(objective.status)}`"
                              class="badge"
                            >
                              {{getStatusLabel(objective.status)}}
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                          <strong :class="`text-${getStatusClass(objective.status)}`">
                            {{objective.objective}}
                          </strong>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" style="margin-top:5px;">
                        <b-row>
                          <b-col cols="2">
                            <b-badge
                              variant="success"
                              class="badge"
                            >
                            Key Result
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                            <strong class="text-success">
                              {{objective.key_result}}
                            </strong> 
                          </b-col>
                        </b-row> 
                      </b-col>
                      <b-col md="12" v-if="objective.notes" style="margin-top:5px;">
                        <b-row>
                          <b-col cols="2">
                            <b-badge
                              variant="warning"
                              class="badge"
                            >
                            Objective Note
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                            <strong class="text-warning">
                              {{objective.notes}}
                            </strong> 
                          </b-col>
                        </b-row> 
                      </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-tab>


              <!-- left position -->
              <b-tab
                :title='"Experiments("+getExperiments.length+")"'
              >
                <b-tabs v-for="(experiment,k) in getExperiments" :key="k"
                  vertical
                  nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
                  <b-tab
                    :title='"Experiment "+(k+1)'
                  >
                    <b-card-text >
                      <b-row>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="2" >
                            <b-badge
                              :variant="`${getStatusClass(experiment.status)}`"
                              class="badge"
                            >
                              {{getStatusLabel(experiment.status)}}
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                            <strong :class="`text-${getStatusClass(experiment.status)}`">
                              {{experiment.objective}}
                            </strong>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" style="margin-top:5px;">
                        <b-row>
                          <b-col cols="2">
                            <b-badge
                              variant="success"
                              class="badge"
                            >
                            Key Result
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                            <strong class="text-success">
                              {{experiment.key_result}}
                            </strong> 
                          </b-col>
                        </b-row> 
                      </b-col>
                      <b-col md="12" v-if="experiment.notes" style="margin-top:5px;">
                        <b-row>
                          <b-col cols="2">
                            <b-badge
                              variant="warning"
                              class="badge"
                            >
                            Experiment Note
                            </b-badge>
                          </b-col>
                          <b-col cols="10">
                            <strong class="text-warning">
                              {{experiment.notes}}
                            </strong> 
                          </b-col>
                        </b-row> 
                      </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-if="($can('Zircly Admin') || isReviewer) && OKRData">
      <!-- Second Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <b-card title="Review this OKR" >
            <b-row>
              <b-col md="6" >
                <b-form-group
                  label="Review"
                  label-for="review"
                >
                <b-form-textarea
                  id="review"
                  v-model="OKRData.review"
                  placeholder="Write Review for this OKR"
                  rows="3"
                />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="OKR Review Status" label-for="review-status">
                    <b-button class="ml-5 mt-2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      @click="saveReview('Approved')"
                    >
                      Approve OKR
                    </b-button>
                    <b-button class="ml-1 mt-2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      @click="saveReview('Rejected')"
                    >
                      Reject OKR
                    </b-button>
                    <b-button class="ml-1 mt-2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :to="{ name: 'okrs' }"
                    >
                      Go Back
                    </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import okrStoreModule from "../okrStoreModule";
import OkrViewInfo from "./OkrViewInfo.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    OkrViewInfo,
    VueGoodTable,
    BBadge,
    BProgress,
    BProgressBar
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const OKRData = ref(null);
    const okr = ref({
      name: "",
      id: "",
    });
    const USER_APP_STORE_MODULE_NAME = "app-okrs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, okrStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-okrs/fetchOkr", router.currentRoute.params.id)
      .then((response) => {
        OKRData.value = response.data.data;

        okr.value.name = OKRData.value.name;
        okr.value.id = OKRData.value.hashid;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          OKRData.value = undefined;
        }
      });
    return {
      OKRData,
      okr,
    };
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
      pageLength: 3,
      okrChangeFlag: true,
      pageLengthEmployee: 3,
      dir: false,
      isAddNewInterviewSidebarActive: false,
      columns: [
        {
          label: "Interview Title",
          field: "title",
        },
        {
          label: "Start At",
          field: "start_at",
        },
        {
          label: "End At",
          field: "end_at",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      customFields: [],
      objectives:[],
      experiments:[],
      review:'',
      statusList:[
        {
          label: "Not Started Yet",
          code: 0,
        },
        {
          label: "In Progress",
          code: 1,
        },
        {
          label: "Completed",
          code: 2,
        },
        {
          label: "On Hold",
          code: 3,
        },
        {
          label: "Cancelled",
          code: 4,
        },
      ],
    };
  },
  created() {
    this.getCustomFields();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
    isReviewer(){
    console.log(this.userDataLocal);
    console.log(this.OKRData);
    if(this.OKRData && this.OKRData.manager.hashid === this.userDataLocal.hashid){
      return true;
    }
    return false;
    },
    getObjectives(){
      if(this.OKRData){
        for(var key in this.OKRData.okr_details){
          if(this.OKRData.okr_details[key].type == 'Objective'){
            this.objectives.push(this.OKRData.okr_details[key]);
          }
        }
        console.log(this.objectives);
        return this.objectives;
      }
      return this.objectives;
    },
    getExperiments(){
      if(this.OKRData){
        for(var key in this.OKRData.okr_details){
          if(this.OKRData.okr_details[key].type == 'Experiment'){
            this.experiments.push(this.OKRData.okr_details [key]);
          }
        }
        return this.experiments;
      }
      return this.experiments;
    },
    getProgress(){
      var statusPercentanges = [];
      var progresses = {
        'Completed':{'value':0,'class':'success'},
        'In Progress':{'value':0,'class':'warning'},
        'Cancelled':{'value':0,'class':'danger'},
        'On Hold':{'value':0,'class':'info'},
        'Not Started Yet':{'value':0,'class':'dark'},
    };
      var totalCount = this.OKRData.okr_details.length;
      for(var key in this.OKRData.okr_details){
        var sk = this.getStatusLabel(this.OKRData.okr_details[key].status);
        if(statusPercentanges[sk]){
        statusPercentanges[sk] += 1;
        }else{
        statusPercentanges[sk] = 1;
        }
      }
      for(var key in statusPercentanges){
        progresses[key].value = ((statusPercentanges[key] / totalCount) * 100).toFixed(2);
      }
      console.log(statusPercentanges);
      return progresses;
    }
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
    assignInterview() {
      console.log("assigning interview...");
      this.isAddNewInterviewSidebarActive = true;
    },
    refetchOkrsData() {
      console.log("refetching started..");
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-okrs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    getStatusClass(status){
    if (status == 0) return "dark";
    if (status == 1) return "warning";
    if (status == 2) return "success";
    if (status == 3) return "info";
    if (status == 4) return "danger";
    return "primary";
    },
    getStatusLabel(status){
      this.statusList.forEach(function(obj,key) {

      });
      var label = "Not Started Yet";
      for(var key in this.statusList){
        if(status == this.statusList[key].code){
          label = this.statusList[key].label
        }
      }
      return label
    },

    saveReview(status) {

      const self = this;
      const formData = new FormData();
      formData.append("reviewer_reason", self.OKRData.review);
      formData.append("hashid", self.OKRData.hashid);
      formData.append("status", status);
      self.$store
        .dispatch("app-okrs/saveReview", formData)
        .then((res) => {
          if(res.data.success){
            self.$router.push("/okrs");
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Review saved successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Review saved successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          }else{
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Review Update Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Review Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          
        })
        .catch((error) => {
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! OKR review update Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR review update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
        
    },
    viewRecord(okr) {
      // this.$router.go(`/view/okr/${okr.hashid}`).catch(() => {});
      this.$router.push({name: 'view-okr', params: {id: okr.hashid}});
      this.$router.go({name: 'view-okr', params: {id: okr.hashid}});
    },
    
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
.scroll {
   overflow:auto; white-space: nowrap;width: 100%; padding: 10px;
}
.scroll::-webkit-scrollbar {
    width: 12px;
    height: 6px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #38B22D;
}
</style>