<template >
  <b-card style="width: 100%; height: 100%">
    <b-row>
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
            <b-button
              v-if="Data.user.hashid == userDataLocal.hashid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="ml-2"
              @click="editRecord(Data)"
            >
              <span>Edit</span>
              <feather-icon icon="ArrowRightIcon" class="ml-25" />
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>OKR Details:</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="5" class="pb-50 font-weight-bold">
                OKR Period Range
              </b-col>
              <b-col md="7" class="pb-50">
                {{ Data.period_range }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="5" class="pb-50 font-weight-bold">
                OKR Review Status
              </b-col>
              <b-col md="7" class="pb-50">
                {{ Data.status_label }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="5" class="pb-50 font-weight-bold"> Employee </b-col>
              <b-col md="7" class="pb-50">
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: Data.user.hashid },
                  }"
                  class="font-weight-bold d-inline-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="Data.user.name"
                >
                  {{ Data.user.name | str_limit(15) }}
                </b-link>
                <span
                  v-else
                  class="d-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="Data.user.name"
                >
                  {{ Data.user.name | str_limit(15) }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="5" class="pb-50 font-weight-bold"> Manager </b-col>
              <b-col md="7" class="pb-50">
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: Data.manager.hashid },
                  }"
                  class="font-weight-bold d-inline-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="Data.manager.name"
                >
                  {{ Data.manager.name | str_limit(15) }}
                </b-link>
                <span
                  v-else
                  class="d-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="Data.manager.name"
                >
                  {{ Data.manager.name | str_limit(15) }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" v-if="canShow">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold"> OKR Review </b-col>
              <b-col class="pb-50">
                {{ Data.review }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="5" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="7" class="pb-50">
            {{ Data.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    Data: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      DataLocal: this.$cookies.get("Data"),
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    editRecord(data) {
      this.$router.push(`/edit/okr/${data.hashid}`).catch(() => {});
    },
  },
  computed: {
    canShow() {
      if (
        (this.Data && this.Data.manager.hashid === this.userDataLocal.hashid) ||
        (this.Data && this.Data.user.hashid === this.userDataLocal.hashid)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
</style>
